import { defineStore } from 'pinia';
import fetchCities from '~/api/cities';
import type { IArtist, ICitiesResponse, TCity } from '~/types/api';
import fetchArtists from '~/api/artists';
import fetchOneArtist from '~/api/artist';
import fetchHeadliners from '~/api/headliners';
import { CITY_LIST } from '~/constants';

const ID = 'common';

type State = {
  isOpenMenu: boolean,
  cities: ICitiesResponse | null,
  artists: Record<TCity, IArtist[]> | null,
  headliners: IArtist[] | null,
};
type Getters = {};
type Actions = {
  toggleMenu: () => void,
  getCityData: () => void,
  getArtistsData: (url: string, cityID: TCity) => void,
  getOneArtistData: (id: number) => Promise<IArtist>,
  getHeadliners: () => void,
};

const useCommonStore = defineStore<typeof ID, State, Getters, Actions>(ID, {
  state: () => ({
    isOpenMenu: false,
    cities: null,
    artists: null,
    headliners: null,
  }),
  getters: {},
  actions: {
    toggleMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    },
    async getCityData() {
      const res = await fetchCities();

      // Сортировка городов по позиции в списке
      this.cities = Object.fromEntries(
        CITY_LIST
          .filter(city => city in res)
          .map(city => [city, res[city]])
      ) as ICitiesResponse;
    },
    async getArtistsData(url, cityID) {
      const hasCity = this.artists?.[cityID];

      if (hasCity) {
        return;
      }

      const res = await fetchArtists(url);
      this.artists = {
        ...this.artists,
        [cityID]: res,
      } as any;
    },
    async getOneArtistData(id) {
      return await fetchOneArtist(id);
    },
    async getHeadliners() {
      const res = await fetchHeadliners();

      this.headliners = res?.filter(artist => !artist.is_hidden_artist);
    },
  },
});

export default useCommonStore;
