export const appRootId = "__vkfest"

export const appBaseURL = "/"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"http-equiv":"X-UA-Compatible","content":"IE=edge,chrome=1"},{"name":"msapplication-TileColor","content":"#000"},{"name":"theme-color","content":"#000"},{"name":"color-scheme","content":"light only"},{"name":"author","content":"https://code-pilots.ru/"},{"name":"format-detection","content":"telephone=no"},{"name":"format-detection","content":"address=no"},{"name":"google","content":"notranslate"},{"name":"title","content":"VK Fest 2024 — билеты и города"},{"name":"description","content":"Главное событие лета: пять городов, полтора месяца, более 100 звёздных гостей"},{"property":"og:locale","content":"ru_RU"},{"property":"og:type","content":"website"},{"property":"og:title","content":"VK Fest 2025 — билеты и города"},{"property":"og:description","content":"Главное событие лета: пять городов, полтора месяца, более 100 звёздных гостей"},{"property":"og:image:width","content":"1200"},{"property":"og:image:height","content":"630"},{"name":"twitter:title","content":"VK Fest 2025 — билеты и города"},{"name":"twitter:description","content":"Главное событие лета: пять городов, полтора месяца, более 100 звёздных гостей"},{"name":"twitter:image","content":"/assets/images/1200х630.png"},{"name":"twitter:card","content":"summary_large_image"}],"link":[{"rel":"shortcut icon","href":"/favicon.ico"}],"style":[],"script":[{"src":"/assets/js/analytics.js","type":"text/javascript","tagPosition":"bodyClose","defer":true}],"noscript":[],"htmlAttrs":{"lang":"ru"},"charset":"utf-8","viewport":"width=device-width, initial-scale=1","title":"VK Fest 2025 — билеты и города"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const appRootAttrs = {"id":"__vkfest"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__vkfest'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true

export const spaLoadingTemplateOutside = false