import { API_BASE_URL } from '~/constants';
import type { IArtist } from '~/types/api';

const fetchOneArtist = async (id: number): Promise<IArtist> => {
  return await fetch(`${API_BASE_URL}/vkfest/artist.php?id=${id}`)
    .then(res => res.json())
    .catch(e => e);
};

export default fetchOneArtist;
