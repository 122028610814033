import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import device_5n0IO2dlsKg7HnXX0bKcB_24CQXGV0kbKRsJJ3LLx1Q from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/plugins/device.ts";
import maska_lyPpjSjnoA3nTYBiva4Tzs90JPX2LI8m_EQh5E7FRc8 from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/plugins/maska.ts";
import matter_client_VcDcO79ZoRIk_f2_bk__sYBBLqN2m1o7zSTZNSq6QL4 from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/plugins/matter.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  device_5n0IO2dlsKg7HnXX0bKcB_24CQXGV0kbKRsJJ3LLx1Q,
  maska_lyPpjSjnoA3nTYBiva4Tzs90JPX2LI8m_EQh5E7FRc8,
  matter_client_VcDcO79ZoRIk_f2_bk__sYBBLqN2m1o7zSTZNSq6QL4
]