import type { ICitiesResponse } from '~/types/api';
import { API_BASE_URL } from '~/constants';

const fetchCities = async (): Promise<ICitiesResponse> => {
  return await fetch(`${API_BASE_URL}/vkfest/landing/cities.json`)
    .then(res => res.json())
    .catch(e => e);

  // return mock;
};

export default fetchCities;
