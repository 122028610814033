
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_93Y70wJYp84a4vmtT70ZY_bazMJmrUlqcJoKNnAGJM3JUMeta } from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/artist/[id].vue?macro=true";
import { default as _91id_93NEKA_45UpjTJmtSzl_F3wKttogxk9syttPUdya3NAgJCUMeta } from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/city/[id].vue?macro=true";
import { default as contact_45usDsJ6ZcW4WlzVHokrieikzyZVURikbPLtZuhVMXz3wPwMeta } from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/contact-us.vue?macro=true";
import { default as appsx4j8MKHeRGIJkix0Zcxa4TzOCM7nTnAmHigNmG1uLxUMeta } from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/index/apps.vue?macro=true";
import { default as indexv_HqJak7K6JArMlr3U3BwpqehJi7xaVEahorbUa1MxYMeta } from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/index.vue?macro=true";
import { default as rulesbTKtcDYV0IYgJebzz1OaUIcSbAmcaQyqkVvK_45FB6YxAMeta } from "/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/rules.vue?macro=true";
export default [
  {
    name: "artist-id",
    path: "/artist/:id()",
    component: () => import("/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/artist/[id].vue")
  },
  {
    name: "city-id",
    path: "/city/:id()",
    meta: _91id_93NEKA_45UpjTJmtSzl_F3wKttogxk9syttPUdya3NAgJCUMeta || {},
    component: () => import("/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/city/[id].vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    meta: contact_45usDsJ6ZcW4WlzVHokrieikzyZVURikbPLtZuhVMXz3wPwMeta || {},
    component: () => import("/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/contact-us.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/index.vue"),
    children: [
  {
    name: "index-apps",
    path: "apps",
    meta: appsx4j8MKHeRGIJkix0Zcxa4TzOCM7nTnAmHigNmG1uLxUMeta || {},
    component: () => import("/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/index/apps.vue")
  }
]
  },
  {
    name: "rules",
    path: "/rules",
    meta: rulesbTKtcDYV0IYgJebzz1OaUIcSbAmcaQyqkVvK_45FB6YxAMeta || {},
    component: () => import("/mnt/cache/teamcity-agent/work/c5c1aadee301441a/src/pages/rules.vue")
  }
]