export default defineNuxtPlugin(() => {
  const isMobile = ref(false);
  const isPortraitTablet = ref(false);
  const isLandscapeTablet = ref(false);
  const isDesktop = ref(false);

  const resizeMedia = () => {
    isMobile.value = window.matchMedia('(max-width: 767px)').matches;
    isPortraitTablet.value = (
      window.matchMedia('(min-width: 767px)').matches &&
      window.matchMedia('(max-width: 1023px)').matches
    );
    isLandscapeTablet.value = (
      window.matchMedia('(min-width: 1024px)').matches &&
      window.matchMedia('(max-width: 1279px)').matches
    );
    isDesktop.value = window.matchMedia('(min-width: 1280px)').matches;
  };

  const isTablet = computed(() => isPortraitTablet.value || isLandscapeTablet.value);

  if (process.client) {
    resizeMedia();
    window.addEventListener('resize', resizeMedia);
  }

  return {
    provide: {
      device: {
        isMobile,
        isTablet,
        isPortraitTablet,
        isLandscapeTablet,
        isDesktop,
      },
    },
  };
});
