import type { TCity } from '~/types/api';

export const API_BASE_URL = 'https://vkfest.code-pilots.ru';

export const CITY_LIST = ['msk', 'spb', 'kaz', 'sochi', 'chlb'] as const;

export const CITY_MAP: Record<TCity, string> = {
  spb: 'Санкт-Петербург',
  msk: 'Москва',
  sochi: 'Сириус',
  chlb: 'Челябинск',
  kaz: 'Казань',
} as const;

export const CITY_MAP_REVERSE: Record<string, TCity> = {
  Москва: 'msk',
  'Санкт-Петербург': 'spb',
  Казань: 'kaz',
  Челябинск: 'chlb',
  Сириус: 'sochi',
} as const;

export const SETTINGS = {
  communityLink: 'https://vk.com/fest',
  vkLink: 'https://vk.com/fest',
  telegramLink: 'https://t.me/vkfest',
  dzenLink: 'https://dzen.ru/vkfest',
  appLink: 'https://app.vkfest.ru',
};

export const SHOWGOGO_SUPPORT_URL = 'https://msk.showgogo.ru/contacts_clean/?theme=dark&skin=vk';
