import { API_BASE_URL } from '~/constants';
import type { IArtist } from '~/types/api';

const fetchHeadliners = async (): Promise<IArtist[]> => {
  return await fetch(`${API_BASE_URL}/vkfest/landing/headliners.json`)
    .then(res => res.json())
    .catch(e => e);
};

export default fetchHeadliners;
